import React from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useOutletContext } from "react-router-dom";
import { postRequest } from "../../axios";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";

const GoogleAuth = (props) => {
  const setSpinner = useOutletContext();

  const responseGoogle = async (response) => {
    setSpinner(true);
    try {
      let res = await postRequest("add-update-google-auth", response);

      setSpinner(false);
      if (res.data.error === 0) {
        SuccessNotificationMsg("Success", res.data.response);
      } else {
        ErrorNotificationMsg(res.data.errmsg);
      }
    } catch (error) {
      ErrorNotificationMsg(error.message);
    }
  }

  return (
    <GoogleLogin
      className="ml-2"
      clientId={props.clientId}
      buttonText="Authorize Google Calender for Live Classes"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
      responseType='code'
      accessType="offline"
      scope="https://www.googleapis.com/auth/calendar"
    />
  );
};

export default GoogleAuth;
